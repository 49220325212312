import Link from "next/link"
import { Alert, type TAlertProps } from "shared-ui"

type TargetPage = "product_list" | "dashboard"

type TAlertNoShipmentProps = Omit<
  TAlertProps,
  "variant" | "description" | "title"
> & {
  targetPage: TargetPage
}

const CONTENT: Record<TargetPage, { title: string; description: JSX.Element }> =
  {
    product_list: {
      title: "Aktifkan Pengiriman Sekarang",
      description: (
        <p>
          Anda perlu memiliki kurir pengiriman aktif untuk menambah atau
          mengubah produk fisik. Atur kurir pengiriman dengan klik{" "}
          <Link
            className="font-semibold text-secondary400"
            href="/settings/shipment"
          >
            di sini
          </Link>
          .
        </p>
      ),
    },
    dashboard: {
      title: "Segera Buat Pengaturan Pengiriman",
      description: (
        <p>
          Anda tidak bisa menambah, menayangkan, dan mengatur produk karena
          belum memiliki kurir pengiriman aktif. Silakan atur pengiriman{" "}
          <Link
            className="font-semibold text-secondary400"
            href="/settings/shipment"
          >
            di sini
          </Link>
          .
        </p>
      ),
    },
  }

const AlertNoShipment = ({ targetPage, ...props }: TAlertNoShipmentProps) => (
  <Alert
    {...props}
    variant="warning"
    title={CONTENT[targetPage].title}
    description={CONTENT[targetPage].description}
  />
)

export default AlertNoShipment
