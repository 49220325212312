"use client"

import { Button, Spinner } from "shared-ui"
import Image from "next/image"
import useGetSellerScore from "../hooks/useGetSellerScore"
import SellerScoreBg from "shared-assets/images/seller-score-bg.png"

type TSellerScoreProps = {
  sellerId: string
  sellerSlug?: string | null
}

const SellerScoreSection = ({ sellerId, sellerSlug }: TSellerScoreProps) => {
  const { data: sellerScore, isFetching: isFetchingSellerScore } =
    useGetSellerScore({
      variables: {
        input: {
          sellerId,
        },
      },
      enabled: !!sellerId,
    })

  const goToSellerProfile = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_BUYER_URL}/${sellerSlug ?? ""}`,
      "_blank"
    )
  }

  return (
    <div className="relative mx-4 mb-4 flex h-[173px] w-[310px] flex-col items-center justify-center gap-4 rounded-4">
      <div className="absolute inset-0 z-0">
        <Image
          src={SellerScoreBg}
          width={310}
          height={173}
          alt="seller-score"
          className="h-[173px]"
        />
      </div>
      <div className="relative flex flex-col items-center justify-center gap-4">
        <div className="text-caption-lg-semibold w-[148px] rounded-b-4 bg-primary500 px-6 py-1 text-center text-tertiary25">
          Penilaian Toko
        </div>
        {isFetchingSellerScore ? (
          <div className="flex h-[54px] items-center justify-center">
            <Spinner size="small" />
          </div>
        ) : (
          <div>
            <span className="text-[36px] font-bold text-tertiary500">
              {sellerScore?.finalScore?.toFixed(0) ?? "-"}
            </span>
            <span className="text-[24px] text-tertiary300">/100</span>
          </div>
        )}
        <Button variant="outline1" onClick={goToSellerProfile} className="mb-4">
          Lihat Penilaian
        </Button>
      </div>
    </div>
  )
}

export default SellerScoreSection
