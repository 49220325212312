import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { GET_COMPANY_PUBLIC } from "../federated/queries"
import {
  type GetCompanyPublicBlacklistQuery,
  type GetCompanyPublicBlacklistQueryVariables,
} from "@/federatedGql/graphql"
import { constructError } from "@/utils/constructError"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TGetCompanyPublicResponse = GenericExtractor<
  GetCompanyPublicBlacklistQuery["companyPublic"]
>

const useGetCompanyPublic = createQuery<
  TGetCompanyPublicResponse,
  GetCompanyPublicBlacklistQueryVariables
>({
  primaryKey: "getCompanyPublic",
  queryFn: async ({ queryKey: [, variables] }) => {
    const result = await federatedGqlClient.request(
      GET_COMPANY_PUBLIC,
      variables
    )
    if (result.companyPublic.__typename === "GenericError") {
      throw Error(constructError(result.companyPublic))
    }

    return result.companyPublic
  },
})

export default useGetCompanyPublic
