import { type GenericError } from "@/federatedGql/graphql"

export function constructError(error: GenericError) {
  return `${error.message}. (Request ID: ${error.reqId || "-"})`
}

export function customConstructError({
  actionName,
  input,
  error,
}: {
  actionName: string
  input: string
  error: GenericError | string
}) {
  return `Error ${actionName}, input: ${input}, error: ${JSON.stringify(error)}`
}
