import { createQuery } from "react-query-kit"

import { GET_INSTITUTION_BY_ID } from "../federated/queries"
import {
  type GetInstitutionByIdQueryVariables,
  type GetInstitutionByIdQuery,
} from "@/federatedGql/graphql"
import { constructError } from "@/utils/constructError"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TGetInstitutionByIdResponse = Extract<
  GetInstitutionByIdQuery["getInstitutionByID"],
  { __typename: "Institution" }
>

export const useGetInstitutionById = createQuery<
  TGetInstitutionByIdResponse,
  GetInstitutionByIdQueryVariables
>({
  primaryKey: "getInstitutionById",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_INSTITUTION_BY_ID,
      variables
    )
    if (res?.getInstitutionByID?.__typename === "GenericError") {
      throw new Error(constructError(res.getInstitutionByID))
    }
    return res.getInstitutionByID
  },
})
