import { graphql } from "@/federatedGql/gql"

export const GET_USER_LOGISTIC_CONFIGURATION = graphql(`
  query getUserLogisticConfiguration {
    userLogisticConfiguration {
      ... on UserLogisticConfigurationResponse {
        __typename
        data {
          code
          id
          isActive
          isShown
          logoUrl
          name
          rates {
            id
            isActive
            isShown
            name
            type
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
