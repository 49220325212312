"use client"

import Link from "next/link"
import { Alert } from "shared-ui"

import useGetSellerProductCount from "./hooks/useGetPeralatanInfo"

const InactiveProductAlert = () => {
  const { sellerProductCount, isFetchingSellerProductCount } =
    useGetSellerProductCount()

  if (!isFetchingSellerProductCount && !!sellerProductCount?.pending) {
    return (
      <Alert
        variant="warning"
        description={
          <p>
            {sellerProductCount.pending} Produk masih belum aktif, silakan
            lengkapi data produk untuk mengaktifkan produk.{" "}
            <Link href="/products" className="font-semibold text-secondary400">
              Lihat Produk.
            </Link>
          </p>
        }
      />
    )
  }

  return null
}

export default InactiveProductAlert
