import { graphql } from "@/federatedGql/gql"

export const GET_SELLER_SCORE = graphql(`
  query getSellerScore($input: SellerScoreInput!) {
    sellerScore(input: $input) {
      ... on SellerScoreResponse {
        details {
          componentName
          notes
          score
          value
          valueUnit
          weightPercentage
        }
        finalScore
        orderSignedCount
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
