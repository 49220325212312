import {
  IS_CANARY_PREPROD,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  IS_STAGING,
} from "shared-utils/constants"

export const ALLOW_SELLER_TO_PROPOSE_ADDENDUM =
  "ORDER-ALLOW_SELLER_TO_PROPOSE_ADDENDUM"

export const DISPLAY_LOGISTIC_TYPE = "LOGISTIC-DISPLAY_LOGISTIC_TYPE"

export const VALIDATE_LOGISTIC_KILL_SWITCH =
  "PRODUCT-VALIDATE_LOGISTIC_KILL_SWITCH"

export const DISPLAY_CURATION_INFO_PROCESS =
  "PRODUCT-DISPLAY_CURATION_INFO_PROCESS"

export const ENTERPRISE_VALIDATION = "MARKETPLACE-ENTERPRISE_VALIDATION"

export const BULK_PRODUCT_MANAGEMENT_GRADUAL_RELEASE =
  "PRODUCT-BULK_PRODUCT_MANAGEMENT_GRADUAL_RELEASE"

export const BULK_PRODUCT_MANAGEMENT_BUTTON_GRADUAL_RELEASE =
  "PRODUCT-BULK_PRODUCT_MANAGEMENT_BUTTON_GRADUAL_RELEASE"

export const UPLOAD_FAKTUR_PAJAK_REPLACEMENT =
  "PAYMENT-UPLOAD_FAKTUR_PAJAK_REPLACEMENT"

export const BLOCK_UPLOAD_EDIT = "PRODUCT-BLOCK_UPLOAD_EDIT"

export const ORDER_HIDE_CANCEL_ORDER_BUTTON = "ORDER-HIDE_CANCEL_ORDER_BUTTON"

export const PRODUCT_MIGRATION_BUTTON_GRADUAL_RELEASE =
  "PRODUCT-MIGRATION_BUTTON_GRADUAL_RELEASE"

export const ORDER_ADDENDUM_QUANTITY_ZERO = "ORDER-ADDENDUM_QUANTITY_ZERO"

export const NEW_CURATION_FLOW_RELEASE = "PRODUCT-NEW_CURATION_FLOW_RELEASE"

export const MARKETPLACE_IMPROVEMENT_REQUIRED_CURATION_PHASE_2 =
  "MARKETPLACE-IMPROVEMENT_REQUIRED_CURATION_PHASE_2"

export const MINICOM_FEATURE = "BUYER-MINI-COMPETITION-ENABLE_MINICOM_FEATURE"

export const ORDER_TERMINATION = "ORDER-ALLOW_INTERNAL_TO_TERMINATE_ORDER"

export const BLACKLIST_INTEGRATION =
  "BLACKLIST-SELLER_CONSUMER_PLATFORM_INTEGRATION"

export const NEW_DEDUCTIONS_GENERATED = "PAYMENT_NEW_DEDUCTIONS_GENERATED"

export const MULTISHIPMENT = "MULTISHIPMENT"

export const BULK_PRODUCT_MANAGEMENT_INFO =
  "MARKETPLACE-BULK_PRODUCT_MANAGEMENT_INFO"

export const DISABLE_MODIFY_PRODUCT_WHEN_PIC_TTD_IS_EMPTY =
  "MARKETPLACE-DISABLE_MODIFY_PRODUCT_WHEN_PIC_TTD_IS_EMPTY"

export const SECTORAL_CURATION = "MARKETPLACE-SECTORAL_CURATION"

export const PAYMENT_HIDE_DEDUCTIONS = "PAYMENT-HIDE_DEDUCTIONS"

export const LIMIT_ALL_TRANSACTION_VALUE = "ORDER-LIMIT_ALL_TRANSACTION_VALUE"

export const SELLER_ORDER_RATING_REVIEW = "PRODUCT-SELLER_ORDER_RATING_REVIEW"

export const PAYMENT_BUPOT_DOCUMENT = "PAYMENT-UPLOAD_TAX_DEDUCTION"

export const KSWP_NOT_VALID_VALIDATION = "MARKETPLACE-KSWP_NOT_VALID_VALIDATION"

export const PRODUCT_PRINCIPAL = "MARKETPLACE-PRODUCT_PRINCIPAL"
export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"

export const STOCK_UNIT_TYPE_V2 = "PRODUCT-SELLER_STOCK_UNIT_TYPE_V2"

export const SHOW_SOCKET_GET_UPLOAD_STATUS_V1 =
  "ACCOUNT-KILL_SWICTH_AUTH_UPLOAD_STATUS_SOCKET"

export const MARKETPLACE_PRODUCT_FORM_AUTO_COMPLETE_CATEGORY_FILTER =
  "MARKETPLACE-PRODUCT_FORM_AUTO_COMPLETE_CATEGORY_FILTER"

export const PRODUCT_PRINCIPAL_BOOK = "MARKETPLACE-PRODUCT_PRINCIPAL_BOOK"

export const PRODUCT_PRINCIPAL_SECTORAL_CONSTRUCTION =
  "MARKETPLACE-PRODUCT_PRINCIPAL_CONSTRUCTION"

export const FLAG_NEW_COMPONENT_AVATAR = !IS_PRODUCTION || IS_CANARY_PREPROD

export const MULTISHIPMENT_MP =
  IS_DEVELOPMENT || IS_STAGING ? "MULTISHIPMENT_DEV_MP" : "MULTISHIPMENT"
export const MULTISHIPMENT_JASA_DIGITAL = "MULTISHIPMENT_JASA_DIGITAL"
