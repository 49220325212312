"use client"
import Link from "next/link"
import { useContext } from "react"
import { Alert, type TAlertProps } from "shared-ui"

import { useGetInstitutionById } from "@/app/products/hooks/useGetInstitutionById"
import { DISABLE_MODIFY_PRODUCT_WHEN_PIC_TTD_IS_EMPTY } from "@/commons/flags"
import { ACCOUNT_PPN_URL } from "@/config/client"
import { FeatureFlagsContext } from "@/contexts/FeatureFlags"

/**
 * When institutionId is not provided, the alert will always be shown
 */
/**
 * When institutionId is provided, call query to get provider picTtd, and show alert if the company is without pic ttd
 */
type WithoutPicTtdAlertProps = Omit<TAlertProps, "variant"> & {
  variant?: TAlertProps["variant"]
} & {
  institutionId?: string
}

const WithoutPicTtdAlert = ({
  variant = "error",
  institutionId,
  ...alertProps
}: Readonly<WithoutPicTtdAlertProps>) => {
  const featureFlags = useContext(FeatureFlagsContext)
  const { data: institution } = useGetInstitutionById({
    enabled:
      featureFlags?.[DISABLE_MODIFY_PRODUCT_WHEN_PIC_TTD_IS_EMPTY]?.isEnabled &&
      !!institutionId,
    variables: {
      id: institutionId ?? "",
    },
  })
  const isWithoutPicTtd =
    institution?.company?.picTtd === "" &&
    Boolean(
      featureFlags?.[DISABLE_MODIFY_PRODUCT_WHEN_PIC_TTD_IS_EMPTY]?.isEnabled
    )
  return (
    (institutionId ? isWithoutPicTtd : true) && (
      <Alert
        title="Akun Anda belum memiliki PIC untuk tandatangan"
        description={
          <span>
            Akun Anda belum memiliki PIC untuk tandatangan, sehingga Anda tidak
            dapat menambahkan produk baru. Silakan lakukan pengaturan
            tandatangan melalui{" "}
            <Link
              className="font-semibold !text-secondary500 hover:underline"
              href={`${ACCOUNT_PPN_URL}/esign-setting/privy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              tautan ini
            </Link>{" "}
            terlebih dahulu.
          </span>
        }
        variant={variant}
        {...alertProps}
      />
    )
  )
}

export default WithoutPicTtdAlert
