"use client"
import Link from "next/link"
import { Alert, type TAlertProps } from "shared-ui"

import useGetCompanyPublic from "./hooks/useGetCompanyPublic"

/**
 * When sellerInstitutionId is not provided, the alert will always be shown
 */
/**
 * When sellerInstitutionId is provided, call query to get company public data, and show alert if the company is blacklisted
 */
type BlacklistedSellerAlertProps = Omit<TAlertProps, "variant"> & {
  variant?: TAlertProps["variant"]
} & {
  sellerInstitutionId?: string
}

const BlacklistedSellerAlert = ({
  variant = "error",
  sellerInstitutionId,
  ...alertProps
}: Readonly<BlacklistedSellerAlertProps>) => {
  const { data: companyPublic } = useGetCompanyPublic({
    enabled: !!sellerInstitutionId,
    variables: {
      filter: {
        institusiIds: [sellerInstitutionId ?? ""],
      },
    },
  })
  const isBlacklisted = companyPublic?.companies?.[0]?.blacklist?.status
  return (
    (sellerInstitutionId ? isBlacklisted : true) && (
      <Alert
        title="Akun Anda Masuk ke Daftar Hitam"
        description={
          <span>
            Akun telah ditangguhkan karena melanggar kebijakan yang telah
            ditetapkan. Jika Anda merasa ini adalah kesalahan atau ingin
            mengajukan banding pencabutan daftar hitam, silakan hubungi{" "}
            <Link
              className="font-semibold !text-secondary500 hover:underline"
              href="https://bantuan.inaproc.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pusat Bantuan
            </Link>{" "}
            kami.
          </span>
        }
        variant={variant}
        {...alertProps}
      />
    )
  )
}

export default BlacklistedSellerAlert
