import { graphql } from "@/federatedGql/gql"

export const GET_LIST_PRODUCT = graphql(`
  query getListProduct(
    $page: Int
    $perPage: Int
    $filter: ListProductFilterInput
  ) {
    listProduct(
      query: { pagination: { page: $page, perPage: $perPage }, filter: $filter }
    ) {
      ... on ListProductRes {
        currentPage
        items {
          brand {
            status
          }
          sni {
            status
          }
          tkdn {
            status
          }
          categoryType
          category {
            id
            isActive
            name
          }
          id
          images {
            id
            imageUrl
          }
          isActive
          name
          prices {
            minPrice
            maxPrice
            minPriceWithTax
            maxPriceWithTax
            productWholesalePrices {
              price
            }
            selectedRegionPrice {
              price
              regionCode
            }
          }
          slug
          status
          stockAccumulation
          type
          username
          variants {
            id
            imageUrl
            isActive
            optionValues
            options
            price
            sku
            sortOrder
            stock
          }
          tax {
            ppnBmPercentage
            ppnPercentage
          }
          labels
          preOrder {
            sla
          }
          curationType
        }
        lastPage
        perPage
        total
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LIST_PRODUCT_DEVELOPMENT = graphql(`
  query getListProductDevelopment(
    $page: Int
    $perPage: Int
    $filter: ListProductFilterInput
  ) {
    listProduct(
      query: { pagination: { page: $page, perPage: $perPage }, filter: $filter }
    ) {
      ... on ListProductRes {
        currentPage
        items {
          brand {
            status
          }
          sni {
            status
          }
          tkdn {
            status
          }
          categoryType
          category {
            id
            isActive
            name
          }
          id
          images {
            id
            imageUrl
          }
          isActive
          name
          prices {
            minPrice
            maxPrice
            minPriceWithTax
            maxPriceWithTax
            productWholesalePrices {
              price
            }
            selectedRegionPrice {
              price
              regionCode
            }
          }
          slug
          status
          stockAccumulation
          type
          username
          variants {
            id
            imageUrl
            isActive
            optionValues
            options
            price
            sku
            sortOrder
            stock
          }
          tax {
            ppnBmPercentage
            ppnPercentage
          }
          labels
          preOrder {
            sla
          }
          curationType
          curation {
            id
            productId
            status
            type
            details {
              curatorRoleId
              curatorRoleName
              description
              id
              notes
              productCurationId
              status
              rejectReasons {
                curationType
                description
                enum
                id
                reason
              }
            }
          }
        }
        lastPage
        perPage
        total
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_USER_LOGISTIC_CONFIGURATION_LIST = graphql(`
  query getUserLogisticConfigurationList {
    userLogisticConfiguration {
      ... on UserLogisticConfigurationResponse {
        __typename
        data {
          code
          id
          isActive
          logoUrl
          name
          rates {
            id
            isActive
            isShown
            name
            type
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PRODUCT_LOGISTIC_CONFIGURATION = graphql(`
  query getProductLogisticConfiguration($productId: ID!) {
    productLogisticConfiguration(productId: $productId) {
      ... on LogisticConfigurationResponse {
        __typename
        data {
          code
          id
          logoUrl
          name
          rates {
            id
            isActive
            isShown
            name
            type
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PRODUCT_BY_ID = graphql(`
  query getProductById($id: ID!) {
    getProduct(id: $id) {
      ... on Product {
        masterProductId
        category {
          id
          name
          isActive
        }
        categoryType
        description
        id
        images {
          id
          imageUrl
        }
        isActive
        kbki
        name
        pdn {
          countryCode
          countryName
          laborDescription
          laborType
          locationDescription
          locationType
          materialDescription
          materialType
          type
        }
        preOrder {
          sla
        }
        prices {
          minPurchase
          productWholesalePrices {
            id
            minQuantity
            price
          }
        }
        tax {
          ppnPercentage
          ppnBmId
        }
        shipping {
          dimensionUnit
          hasSellerShipping
          height
          length
          weight
          weightUnit
          width
        }
        productInformations {
          additionalInformations {
            id
            name
            value
            customFormFieldId
          }
          documents {
            name
            value
            id
            customFormFieldId
          }
          mainInformations {
            value
            name
            id
            customFormFieldId
          }
        }
        productOptions {
          id
          option
          optionLevel
          valueIds
          values
        }
        productAddOns {
          description
          id
          name
          type
          productAddOnVariants {
            description
            id
            name
            price
          }
        }
        status
        slug
        stockUnit {
          value
          secondaryUnit
          primaryUnit
        }
        type
        username
        variants {
          id
          imageUrl
          isActive
          optionValues
          options
          price
          sku
          stock
        }
        videoSource
        videoUrl
        shipping {
          weight
          weightUnit
          width
          length
          height
          dimensionUnit
          hasSellerShipping
        }
        shippingOption
        tkdn {
          value
          url
          type
          standard
          specificationId
          productType
          producerName
          number
          hs
          expirationDate
          email
          description
          date
          companyName
          brand
          bmpExpirationDate
          bmpValue
          bmpDate
          status
        }
        curationType
        sni {
          spptNumber
          sniNumber
          publishedDate
          productName
          expirationDate
          companyName
          certificationScheme
          certificateNumber
          brand
          accreditationScheme
          status
        }
        brand {
          url
          statusName
          registrationNumber
          ownerName
          fillingDate
          expirationDate
          brandName
          applicationNumber
          status
        }
        productAddOns {
          id
          name
          description
          type
          objectType
          tax {
            ppnPercentage
            ppnTypes
          }
          productAddOnVariants {
            price
            name
            id
            description
          }
        }
        actionReasons {
          description
          enum
          reason
        }
        actionReasonsNotes
        subType
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const GET_PRODUCT_BY_ID_DEVELOPMENT = graphql(`
  query getProductByIdDevelopment($id: ID!) {
    getProduct(id: $id) {
      ... on Product {
        masterProductId
        category {
          id
          name
          isActive
        }
        categoryType
        description
        id
        images {
          id
          imageUrl
        }
        isActive
        kbki
        name
        pdn {
          countryCode
          countryName
          laborDescription
          laborType
          locationDescription
          locationType
          materialDescription
          materialType
          type
        }
        preOrder {
          sla
        }
        prices {
          minPurchase
          productWholesalePrices {
            id
            minQuantity
            price
          }
        }
        tax {
          ppnPercentage
          ppnBmId
        }
        shipping {
          dimensionUnit
          hasSellerShipping
          height
          length
          weight
          weightUnit
          width
        }
        productInformations {
          additionalInformations {
            id
            name
            value
            customFormFieldId
          }
          documents {
            name
            value
            id
            customFormFieldId
          }
          mainInformations {
            value
            name
            id
            customFormFieldId
          }
        }
        productOptions {
          id
          option
          optionLevel
          valueIds
          values
        }
        productAddOns {
          description
          id
          name
          type
          productAddOnVariants {
            description
            id
            name
            price
          }
        }
        status
        slug
        stockUnit {
          value
          secondaryUnit
          primaryUnit
        }
        type
        username
        variants {
          id
          imageUrl
          isActive
          optionValues
          options
          price
          sku
          stock
        }
        videoSource
        videoUrl
        shipping {
          weight
          weightUnit
          width
          length
          height
          dimensionUnit
          hasSellerShipping
        }
        shippingOption
        tkdn {
          value
          url
          type
          standard
          specificationId
          productType
          producerName
          number
          hs
          expirationDate
          email
          description
          date
          companyName
          brand
          bmpExpirationDate
          bmpValue
          bmpDate
          status
        }
        curationType
        curation {
          id
          productId
          status
          type
          details {
            curatorRoleId
            curatorRoleName
            description
            id
            notes
            productCurationId
            status
            rejectReasons {
              curationType
              description
              enum
              id
              reason
            }
          }
        }
        sni {
          spptNumber
          sniNumber
          publishedDate
          productName
          expirationDate
          companyName
          certificationScheme
          certificateNumber
          brand
          accreditationScheme
          status
        }
        brand {
          url
          statusName
          registrationNumber
          ownerName
          fillingDate
          expirationDate
          brandName
          applicationNumber
          status
        }
        productAddOns {
          id
          name
          description
          type
          objectType
          tax {
            ppnPercentage
            ppnTypes
          }
          productAddOnVariants {
            price
            name
            id
            description
          }
        }
        actionReasons {
          description
          enum
          reason
        }
        actionReasonsNotes
        subType
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const GET_PRODUCT_BY_ID_DEVELOPMENT_KFA = graphql(`
  query getProductByIdDevelopmentKFA($id: ID!) {
    getProduct(id: $id) {
      ... on Product {
        masterProductId
        category {
          id
          name
          isActive
        }
        categoryType
        description
        id
        images {
          id
          imageUrl
        }
        isActive
        kbki
        name
        pdn {
          countryCode
          countryName
          laborDescription
          laborType
          locationDescription
          locationType
          materialDescription
          materialType
          type
        }
        preOrder {
          sla
        }
        prices {
          minPurchase
          productWholesalePrices {
            id
            minQuantity
            price
          }
        }
        tax {
          ppnPercentage
          ppnBmId
        }
        shipping {
          dimensionUnit
          hasSellerShipping
          height
          length
          weight
          weightUnit
          width
        }
        productInformations {
          additionalInformations {
            id
            name
            value
            customFormFieldId
          }
          documents {
            name
            value
            id
            customFormFieldId
          }
          mainInformations {
            value
            name
            id
            customFormFieldId
          }
        }
        productOptions {
          id
          option
          optionLevel
          valueIds
          values
        }
        productAddOns {
          description
          id
          name
          type
          productAddOnVariants {
            description
            id
            name
            price
          }
        }
        status
        slug
        stockUnit {
          value
          secondaryUnit
          primaryUnit
        }
        type
        username
        variants {
          id
          imageUrl
          isActive
          optionValues
          options
          price
          sku
          stock
        }
        videoSource
        videoUrl
        shipping {
          weight
          weightUnit
          width
          length
          height
          dimensionUnit
          hasSellerShipping
        }
        shippingOption
        tkdn {
          value
          url
          type
          standard
          specificationId
          productType
          producerName
          number
          hs
          expirationDate
          email
          description
          date
          companyName
          brand
          bmpExpirationDate
          bmpValue
          bmpDate
          status
        }
        curationType
        curation {
          id
          productId
          status
          type
          details {
            curatorRoleId
            curatorRoleName
            description
            id
            notes
            productCurationId
            status
            rejectReasons {
              curationType
              description
              enum
              id
              reason
            }
          }
        }
        sni {
          spptNumber
          sniNumber
          publishedDate
          productName
          expirationDate
          companyName
          certificationScheme
          certificateNumber
          brand
          accreditationScheme
          status
        }
        brand {
          url
          statusName
          registrationNumber
          ownerName
          fillingDate
          expirationDate
          brandName
          applicationNumber
          status
        }
        productAddOns {
          id
          name
          description
          type
          objectType
          tax {
            ppnPercentage
            ppnTypes
          }
          productAddOnVariants {
            price
            name
            id
            description
          }
        }
        actionReasons {
          description
          enum
          reason
        }
        actionReasonsNotes
        kfa {
          kfaCode
          packageKfaCode
          name
          isConsolidation
          isJkn
        }
        book {
          author
          title
          edition
          schoolLevel {
            name
          }
          schoolClass {
            name
          }
          subjects {
            name
          }
          publisher {
            name
          }
          isbn
          publicationYear
          referenceId
        }
        construction {
          name
          tools {
            coefficient
            id
            inputCoefficient
            name
          }
          workerPackages {
            coefficient
            id
            inputCoefficient
            name
          }
          materials {
            coefficient
            id
            inputCoefficient
            name
          }
          id
          jobType
        }
        subType
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const GET_SEARCH_SNI = graphql(`
  query getSearchSNI($query: SearchSNIInput) {
    getSearchSNI(query: $query) {
      ... on SNIList {
        total
        perPage
        items {
          accreditationScheme
          brand
          certificateNumber
          certificationScheme
          companyName
          expirationDate
          productName
          publishedDate
          sniNumber
          spptNumber
          status
        }
        lastPage
        currentPage
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_KBKI_LIST = graphql(`
  query getKBKIList(
    $level: KBKILevel
    $search: String
    $parentCodes: [String]
    $page: Int
    $perPage: Int
    $codes: [String]
  ) {
    getAllKBKI(
      level: $level
      search: $search
      parentCodes: $parentCodes
      page: $page
      perPage: $perPage
      codes: $codes
    ) {
      total
      perPage
      items {
        classification
        code
        id
        level
        title
        parentCode
      }
    }
  }
`)

export const GET_ALL_MASTER_PRODUCT = graphql(`
  query getAllMasterProduct($categoryId: ID!) {
    getListMasterProduct(categoryId: $categoryId) {
      ... on ListMasterProductRes {
        __typename
        items {
          id
          name
          kbki
          nationalMaximumRetailPrice
          type
          videoSource
          videoUrl
          pdn {
            laborType
            locationType
            materialType
          }
          tkdn {
            number
            specificationId
            value
          }
          sni {
            brand
            certificateNumber
            companyName
            spptNumber
            sniNumber
          }
          brand {
            applicationNumber
            brandName
          }
          images {
            imageUrl
          }
          regionPrices {
            regionCode
            maximumRetailPrice
          }
          informations {
            additionalInformations {
              value
              name
              id
              customFormFieldId
            }
            mainInformations {
              id
              name
              value
              customFormFieldId
            }
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_MASTER_PRODUCT_BY_ID = graphql(`
  query getMasterProductById($id: ID!) {
    getMasterProduct(id: $id) {
      ... on MasterProduct {
        id
        name
        kbki
        nationalMaximumRetailPrice
        type
        videoSource
        videoUrl
        pdn {
          laborType
          locationType
          materialType
        }
        tkdn {
          number
          specificationId
          value
        }
        sni {
          brand
          certificateNumber
          companyName
          spptNumber
          sniNumber
        }
        brand {
          applicationNumber
          brandName
        }
        images {
          imageUrl
        }
        regionPrices {
          regionCode
          maximumRetailPrice
        }
        informations {
          additionalInformations {
            value
            name
            id
            customFormFieldId
          }
          mainInformations {
            id
            name
            value
            customFormFieldId
          }
        }
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const GET_PRODUCT_BRAND = graphql(`
  query getProductBrand($input: ProductBrandInput!) {
    productBrand(input: $input) {
      ... on ProductBrand {
        brand {
          applicationNumber
          statusName
          status
          brandName
          registrationNumber
          ownerName
          fillingDate
          expirationDate
          url
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_CATEGORY_LIST = graphql(`
  query getAllProductCategoryList($input: ListCategoryInput) {
    getAllProductCategory(input: $input) {
      ... on ProductCategories {
        __typename
        currentPage
        items {
          id
          name
          type
          productType
          hasVat
          curationEnabled
          ppnPercentage
          isActive
          kbli
          allowedPpnPercentage
          isLocalTax
          zoningPriceEnabled
        }
        total
        perPage
        lastPage
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_CATEGORY_LIST_DEVELOPMENT = graphql(`
  query getAllProductCategoryListDevelopment($input: ListCategoryInput) {
    getAllProductCategory(input: $input) {
      ... on ProductCategories {
        __typename
        currentPage
        items {
          id
          name
          type
          productType
          hasVat
          curationEnabled
          ppnPercentage
          isActive
          kbli
          allowedPpnPercentage
          isLocalTax
          integrationSource
          zoningPriceEnabled
        }
        total
        perPage
        lastPage
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_SPECIAL_PRODUCT_REGION_PRICE = graphql(`
  query getSpecialProductRegionPrice($productId: ID!) {
    specialProductRegionPrice(productId: $productId) {
      ... on ProductRegionPrices {
        __typename
        items {
          id
          parentRegionCode
          price
          regionCode
          regionLevel
          regionName
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PRODUCT_CATEGORY_BY_ID_DEVELOPMENT = graphql(`
  query getProductCategoryByIdDevelopment($id: ID!) {
    getProductCategory(id: $id) {
      ... on ProductCategory {
        additionalInfoCustomFormId
        curationEnabled
        createdAt
        deletedAt
        hetPriceEnabled
        icon
        id
        kbki {
          classCodes
          commodityCodes
          commodityGroupCodes
          divisionCodes
          groupCodes
          sectionCodes
          subclassCodes
        }
        primaryInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        additionalInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        kbli
        level
        masterProductEnabled
        name
        nieEnabled
        parentId
        primaryInfoCustomFormId
        slug
        subcategoriesCount
        updatedAt
        zoningPriceEnabled
        type
        productType
        hasVat
        documents {
          id
          name
          mandatory
          order
          showOnLogin
          type
          customFormId
        }
        ppnPercentage
        isActive
        allowedPpnPercentage
        isLocalTax
        integrationSource
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_PRODUCT_CATEGORY_BY_ID_DEV = graphql(`
  query getProductCategoryByIdDev($id: ID!) {
    getProductCategory(id: $id) {
      ... on ProductCategory {
        additionalInfoCustomFormId
        curationEnabled
        createdAt
        deletedAt
        hetPriceEnabled
        icon
        id
        kbki {
          classCodes
          commodityCodes
          commodityGroupCodes
          divisionCodes
          groupCodes
          sectionCodes
          subclassCodes
        }
        primaryInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        additionalInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        kbli
        level
        masterProductEnabled
        name
        nieEnabled
        parentId
        primaryInfoCustomFormId
        slug
        subcategoriesCount
        updatedAt
        zoningPriceEnabled
        integrationSource
        integrationProvider
        type
        productType
        hasVat
        documents {
          id
          name
          mandatory
          order
          showOnLogin
          type
          customFormId
        }
        ppnPercentage
        isActive
        allowedPpnPercentage
        isLocalTax
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_PRODUCT_CATEGORY_BY_ID = graphql(`
  query getProductCategoryById($id: ID!) {
    getProductCategory(id: $id) {
      ... on ProductCategory {
        additionalInfoCustomFormId
        curationEnabled
        createdAt
        deletedAt
        hetPriceEnabled
        icon
        id
        kbki {
          classCodes
          commodityCodes
          commodityGroupCodes
          divisionCodes
          groupCodes
          sectionCodes
          subclassCodes
        }
        primaryInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        additionalInfo {
          id
          name
          type
          defaultValue
          mandatory
          order
          customFormId
          additionalInfo
          showOnLogin
        }
        kbli
        level
        masterProductEnabled
        name
        nieEnabled
        parentId
        primaryInfoCustomFormId
        slug
        subcategoriesCount
        updatedAt
        integrationProvider
        type
        productType
        hasVat
        documents {
          id
          name
          mandatory
          order
          showOnLogin
          type
          customFormId
        }
        ppnPercentage
        isActive
        allowedPpnPercentage
        isLocalTax
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_TKDN_BY_NUMBER = graphql(`
  query getAllTKDNByNumber($number: String) {
    getSearchTKDN(number: $number) {
      ... on ListTKDN {
        __typename
        items {
          specificationId
          number
          productType
          brand
          type
          description
          standard
          value
          hs
        }
      }
      ... on GenericError {
        __typename
        code
        reqId
        message
      }
    }
  }
`)

export const CHECK_MASTER_PRODUCT_AVAILABILITY = graphql(`
  query checkMasterProductAvailability($masterProductId: ID!) {
    checkMasterProductAvailability(masterProductId: $masterProductId) {
      ... on MasterProductAvailability {
        __typename
        available
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_TAX_VALUE = graphql(`
  query getTaxValue {
    tax {
      ... on Tax {
        __typename
        pph22
        pph22Percentage
        pph23
        pph23Percentage
        ppn
        ppnPercentage
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_ALL_MINIFIED_CATEGORY = graphql(`
  query getAllMinifiedCategory($input: AllMinifiedProductCategoryInput!) {
    allMinifiedProductCategory(input: $input) {
      ... on AllMinifiedProductCategories {
        __typename
        items {
          id
          level
          name
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LIST_PPN = graphql(`
  query getListPpn {
    listPpn {
      ... on ListPpn {
        __typename
        items {
          description
          ppnPercentage
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LIST_PPNBM = graphql(`
  query getListPpnBm {
    ppnBm {
      ... on PpnBm {
        __typename
        items {
          description
          id
          level
          name
          parentId
          percentage
        }
      }

      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_KBLI_LIST = graphql(`
  query getKBLIList($codes: [String], $perPage: Int) {
    getAllKBLI(codes: $codes, perPage: $perPage) {
      total
      perPage
      items {
        code
        title
        classification
        description
        id
        parentCode
        level
      }
    }
  }
`)

export const GET_COUNTRY_LIST = graphql(`
  query getCountryList($page: Int, $perPage: Int, $filter: CountryFilter!) {
    countries(
      query: { pagination: { page: $page, perPage: $perPage }, filter: $filter }
    ) {
      ... on Countries {
        items {
          code
          name
        }
        lastPage
        perPage
        total
        currentPage
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const CHECK_PRODUCT_NAME_AVAILABILITY = graphql(`
  query checkProductNameAvailability($name: String!, $productID: String) {
    checkProductNameAvailability(name: $name, productID: $productID) {
      ... on ProductNameAvailability {
        __typename
        available
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_INSTITUTION_BY_ID = graphql(`
  query getInstitutionById($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        company {
          bentukUsaha
          bidangIndustri
          id
          name
          status
          isUmkk
          slug
          companyAddresses {
            fullAddress
            isMainAddress
            label
            latitude
            longitude
            notes
            postalCode
            villageAreaCode
            phoneNumber
          }
          shippingAddresses {
            villageAreaCode
            receiverName
            postalCode
            phoneNumber
            notes
            longitude
            latitude
            label
            isMainAddress
            id
            fullAddress
          }
          oss {
            project {
              kbli {
                id
                name
                kbli
              }
            }
          }
          picTtd
          kswp
        }
        institution {
          institutionType
        }
        klpd {
          jenisKlpd
          kodeKlpd
          namaKlpd
          namaKlpdEcatalog
          namaKlpdPadi
          namaKlpdSakti
          namaKlpdSipd
          namaKlpdSirup
        }
        satker {
          institutionId
          jenisSatker
          kodeSatker
          namaSatker
          namaSatkerEcatalog
          namaSatkerPadi
          namaSatkerSakti
          namaSatkerSipd
          namaSatkerSirup
          namaSatkerTrx
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_INSTITUTION_BY_ID_WITH_BLACKLIST = graphql(`
  query getInstitutionByIdWithBlacklist($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        company {
          bentukUsaha
          bidangIndustri
          id
          name
          status
          isUmkk
          slug
          companyAddresses {
            fullAddress
            isMainAddress
            label
            latitude
            longitude
            notes
            postalCode
            villageAreaCode
            phoneNumber
          }
          shippingAddresses {
            villageAreaCode
            receiverName
            postalCode
            phoneNumber
            notes
            longitude
            latitude
            label
            isMainAddress
            id
            fullAddress
          }
          oss {
            project {
              kbli {
                id
                name
                kbli
              }
            }
          }
          blacklist {
            status
          }
          picTtd
          kswp
        }
        institution {
          institutionType
        }
        klpd {
          jenisKlpd
          kodeKlpd
          namaKlpd
          namaKlpdEcatalog
          namaKlpdPadi
          namaKlpdSakti
          namaKlpdSipd
          namaKlpdSirup
        }
        satker {
          institutionId
          jenisSatker
          kodeSatker
          namaSatker
          namaSatkerEcatalog
          namaSatkerPadi
          namaSatkerSakti
          namaSatkerSipd
          namaSatkerSirup
          namaSatkerTrx
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const CREATE_BULK_DOWNLOAD_REQUEST = graphql(`
  mutation bulkDownloadRequest($input: CreateProductBulkUploadRequestInput!) {
    createProductBulkUploadRequest(input: $input) {
      ... on CreateProductBulkUploadRequestResponse {
        id
      }
      ... on GenericError {
        __typename
        code
        reqId
        message
      }
    }
  }
`)

export const GET_STATUS_DOWNLOAD = graphql(`
  query getStatusDownload($input: ProductBulkUploadRequestInput!) {
    productBulkUploadRequest(input: $input) {
      ... on ProductBulkUploadRequestResponse {
        total
        perPage
        items {
          id
          status
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LIST_PRODUCT_MIGRATION = graphql(`
  query getListProductMigration($input: ListProductMigrationInput!) {
    listProductMigration(input: $input) {
      ... on ListProductMigrationResponse {
        __typename
        total
        perPage
        lastPage
        currentPage
        items {
          description
          id
          name
          url
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_KFA_PRODUCT = graphql(`
  query getKfaProduct($input: KFAProductInput!) {
    kfaProduct(input: $input) {
      ... on KFAProduct {
        __typename
        tradeName
        name
        kfaCode
        isJkn
        isConsolidation
        nie
        manufacturer
        hetPrice
        fixPrice
        dosePerUnit
        generic
        indication
        madeIn
        packageKfaCode
        state
        sideEffect
        warning
        uom {
          name
        }
        ucum {
          csCode
          name
        }
        packagingIds {
          kfaCode
          name
          packPrice
          quantity
          uomId
        }
        productTemplate {
          active
          displayName
          kfaCode
          name
          state
          updatedAt
        }
        identifierIds {
          code
          name
          sourceName
          sourceCode
          url
          endDate
        }
        farmalkesType {
          code
          group
          name
        }
        dosageUsage {
          bodyWeightMax
          bodyWeightMin
          category
          displayName
          duration
          durationMax
          durationUcum
          frequency
          frequencyMax
          name
          period
          periodUcum
          quantity
          quantityHigh
          quantityUom
          updatedAt
          useUcum
        }
        dosageForm {
          code
          name
        }
        description
        controlledDrug {
          code
          name
        }
        administrationRoute {
          code
          name
        }
        activeIngredientsFlattened
        active
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_PRODUCT_EXTERNAL_DATA_LIST = graphql(`
  query getProductExternalDataList($input: ProductExternalDataListInput!) {
    productExternalDataList(input: $input) {
      ... on ProductExternalDataListResponse {
        __typename
        total
        perPage
        items {
          referenceId
          referenceType
          name
          id
          description
          deletedAt
          createdAt
          categoryId
          updatedAt
        }
        lastPage
        currentPage
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_PRODUCT_BOOK = graphql(`
  query getProductBook($input: ProductBookInput!) {
    productBook(input: $input) {
      ... on ProductBook {
        __typename
        author
        title
        edition
        schoolLevel {
          name
        }
        schoolClass {
          name
        }
        subjects {
          name
        }
        publisher {
          name
        }
        isbn
        publicationYear
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_PRODUCT_CONSTRUCTION = graphql(`
  query getProductConstruction($input: ProductConstructionInput!) {
    productConstruction(input: $input) {
      ... on ProductJobDetail {
        __typename
        name
        tools {
          coefficient
          id
          inputCoefficient
          name
        }
        workerPackages {
          coefficient
          id
          inputCoefficient
          name
        }
        materials {
          coefficient
          id
          inputCoefficient
          name
        }
        id
        jobType
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)
