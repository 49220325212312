"use client"
import { type Session } from "next-auth"

import Skeleton from "@/components/skeleton"

type TTitle = {
  session?: Session | null
}

const Title = ({ session }: TTitle) => {
  return (
    <div>
      <h1 className="flex items-center gap-1 text-xl font-bold text-black100">
        Selamat Datang,{" "}
        {session?.user?.fullName ? (
          <span className="capitalize text-secondary500">
            {session?.user.fullName}
          </span>
        ) : (
          <Skeleton bgColor="bg-tertiary50" height="h-6" width="w-20" />
        )}
      </h1>
      <span className="text-sm text-tertiary300">
        Kelola penjualan, inventaris, dan pelanggan dengan mudah untuk
        mengembangkan bisnis Anda.
      </span>
    </div>
  )
}

export default Title
