import { useQuery } from "@tanstack/react-query"

import { GET_SELLER_PRODUCT_COUNT } from "../federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

const useGetSellerProductCount = () => {
  const { data: sellerProductCount, isFetching: isFetchingSellerProductCount } =
    useQuery({
      queryKey: ["getSellerProductCount"],
      queryFn: async () => {
        const res = await federatedGqlClient.request(GET_SELLER_PRODUCT_COUNT)
        if (res.sellerProductCount.__typename === "GenericError") {
          throw new Error(res.sellerProductCount.message || "Error")
        }
        return res.sellerProductCount
      },
    })

  return {
    sellerProductCount,
    isFetchingSellerProductCount,
  }
}

export default useGetSellerProductCount
