import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { GET_USER_LOGISTIC_CONFIGURATION } from "@/app/(sidebar-layout)/settings/shipment/federated/queries"
import {
  type GetUserLogisticConfigurationListQueryVariables,
  type GetUserLogisticConfigurationQuery,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TGetUserLogisticConfigurationQuery = GenericExtractor<
  GetUserLogisticConfigurationQuery["userLogisticConfiguration"]
>

export const getUserLogisticConfiguration = async (
  variables?: GetUserLogisticConfigurationListQueryVariables
): Promise<TGetUserLogisticConfigurationQuery> => {
  const result = await federatedGqlClient.request(
    GET_USER_LOGISTIC_CONFIGURATION,
    variables
  )

  if (result.userLogisticConfiguration.__typename === "GenericError") {
    const { code, message, reqId } = result.userLogisticConfiguration
    throw new Error(`Error ${code}:  ${reqId} - ${message}`)
  }

  return result.userLogisticConfiguration
}

const useGetUserLogisticConfiguration = createQuery<
  TGetUserLogisticConfigurationQuery,
  GetUserLogisticConfigurationListQueryVariables
>({
  primaryKey: "getUserLogisticConfiguration",
  queryFn: ({ queryKey: [, variables] }) =>
    getUserLogisticConfiguration(variables),
})

export default useGetUserLogisticConfiguration
