"use client"
import Image from "next/image"
import { useState } from "react"
import ImageGallery, { type ReactImageGalleryItem } from "react-image-gallery"
import SellerBanner1 from "shared-assets/images/seller-banners/seller-banner-1.png"
import { HELPDESK_URL } from "shared-utils"

import { ButtonRight, ButtonLeft } from "./button"

import "react-image-gallery/styles/css/image-gallery.css"
import "./style.css"

type TBannerSliderProps = {
  data: string[]
}

export const BannerSlider = ({ data }: TBannerSliderProps) => {
  const [isImageError, setIsImageError] = useState<boolean>(false)
  const fallbackImgUrl = SellerBanner1

  const renderImage = (item: ReactImageGalleryItem) => {
    if (!item || !item.original) {
      return null
    }

    const ImageComponent = (
      <Image
        src={isImageError ? fallbackImgUrl : item.original}
        width={310}
        height={162}
        alt="banner-dashboard"
        className="h-[162px] w-[310px]"
        onError={() => setIsImageError(true)}
      />
    )

    if (item.description) {
      return (
        <a href={item.description} target="_blank" rel="noopener noreferrer">
          {ImageComponent}
        </a>
      )
    }

    return ImageComponent
  }

  const isValidImage = (url: string): boolean => {
    return (
      url.indexOf("govmart/seller-banner") > -1 ||
      url.match(/\.(jpeg|jpg|gif|png)$/) != null
    )
  }

  const bannerDescUrl = (index: number) => {
    return [
      HELPDESK_URL.tutorial_manajemen_produk,
      HELPDESK_URL.panduan_penyedia,
      HELPDESK_URL.landing,
    ][index]
  }

  const items = data.map((dt: string, index) => {
    const temp: ReactImageGalleryItem = {
      original: dt,
      description: bannerDescUrl(index),
    }

    if (isValidImage(dt)) {
      temp.renderItem = renderImage
      temp.thumbnail = dt
    }

    return temp
  })

  return (
    <div className="mx-4 mb-6">
      <ImageGallery
        items={items}
        lazyLoad={true}
        showBullets={true}
        infinite={true}
        showPlayButton={false}
        additionalClass="banner-image-gallery"
        renderRightNav={ButtonRight}
        renderLeftNav={ButtonLeft}
        showFullscreenButton={false}
        showThumbnails={false}
        autoPlay={true}
        slideDuration={1000}
        showNav={true}
        slideInterval={5000}
      />
    </div>
  )
}

export default BannerSlider
