import { graphql } from "@/federatedGql/gql"

export const GET_SELLER_PRODUCT_COUNT = graphql(`
  query getSellerProductCount {
    sellerProductCount {
      ... on SellerProductCountRes {
        __typename
        active
        blocked
        pending
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_COMPANY_PUBLIC = graphql(`
  query getCompanyPublicBlacklist($filter: CompanyPublicFilter!) {
    companyPublic(filter: $filter) {
      ... on CompanyPublicResult {
        __typename
        companies {
          blacklist {
            status
            startDate
            endDate
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)
