import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"
import { constructError } from "@/utils/constructError"

import { GET_SELLER_SCORE } from "../federated/queries"
import {
  type GetSellerScoreQuery,
  type GetSellerScoreQueryVariables,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TGetSellerScore = GenericExtractor<
  GetSellerScoreQuery["sellerScore"]
>

const useGetSellerScore = createQuery<
  TGetSellerScore,
  GetSellerScoreQueryVariables
>({
  primaryKey: "getSellerScore",
  queryFn: async ({ queryKey: [, variables] }) => {
    const result = await federatedGqlClient.request(GET_SELLER_SCORE, variables)
    if (result.sellerScore.__typename === "GenericError") {
      throw Error(constructError(result.sellerScore))
    }
    return result.sellerScore
  },
})

export default useGetSellerScore
