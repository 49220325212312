"use client"
import { useContext } from "react"

import AlertNoShipment from "@/components/alert-no-shipment"

import useGetUserLogisticConfiguration from "@/app/products/hooks/useGetUserLogisticConfiguration"
import { VALIDATE_LOGISTIC_KILL_SWITCH } from "@/commons/flags"
import { FeatureFlagsContext } from "@/contexts/FeatureFlags"

const InactiveLogisticAlert = () => {
  const featureFlags = useContext(FeatureFlagsContext)
  const { data: userLogisticConfiguration, isLoading } =
    useGetUserLogisticConfiguration({ select: (resData) => resData.data })

  const isLogisticNotAvailable =
    !featureFlags?.[VALIDATE_LOGISTIC_KILL_SWITCH]?.isEnabled &&
    userLogisticConfiguration?.every((item) => !item.isActive)

  if (!isLogisticNotAvailable || isLoading) {
    return null
  }

  return <AlertNoShipment targetPage="dashboard" />
}

export default InactiveLogisticAlert
